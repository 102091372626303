<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    useButton?: boolean
    closedHeight: number
    disabled?: boolean
    buttonText?: { isClosed?: string, isOpened?: string }
    buttonPlacement?: 'left' | 'center'
  }>(),
  {
    useButton: true,
    buttonText: undefined,
    buttonPlacement: 'center',
  },
)

const emit = defineEmits<{
  'update:content-isOverflowed': [isOverflowed: boolean]
}>()

const gsap = (await import('gsap')).default

const contentController = templateRef<HTMLElement>('contentController')
const contentWrapper = templateRef<HTMLElement>('contentWrapper')

let initialized = false

const { height: realContentHeight } = useElementBounding(contentController)
useResizeObserver(contentController, useDebounceFn(() => toggleHeight(), 400))

const isLocalOpened = defineModel<boolean>('isOpened', { default: false })

const isContentOverflowed = computed(
  () => realContentHeight.value > props.closedHeight,
)

onMounted(() => {
  toggleHeight(isLocalOpened.value)
})

watch(
  isLocalOpened,
  () => {
    toggleHeight()
  },
)

watch(isContentOverflowed, isOverflowed => emit('update:content-isOverflowed', isOverflowed))

function toggleHeight(animate = true) {
  const isOpened = isLocalOpened.value && isContentOverflowed.value && !props.disabled
  const duration = initialized && animate ? 0.4 : 0

  gsap.to(contentWrapper.value, {
    duration,
    ease: 'expo.inOut',
    height: !isOpened && !props.disabled && isContentOverflowed.value ? props.closedHeight : 'auto',
  })

  initialized = true
}
</script>

<template>
  <div
    class="collapsable_wrapper transform-all relative duration-300"
    :class="{
      'closed': !isLocalOpened && isContentOverflowed && !disabled,
      'pb-10': isContentOverflowed && isLocalOpened && useButton,
      useButton,
    }"
  >
    <div ref="contentWrapper" class="relative overflow-hidden">
      <div ref="contentController">
        <slot />
      </div>
    </div>

    <BaseButtonToggle
      v-if="isContentOverflowed && !disabled && useButton"
      v-model:opened="isLocalOpened"
      class="lg:xs-text absolute bottom-0 z-[2] translate-y-5 text-sm"
      :class="{
        'left-1/2 -translate-x-1/2 p-5': buttonPlacement === 'center',
        'left-0 py-5': buttonPlacement === 'left',
      }"
      :closed-text="buttonText?.isClosed"
      :opened-text="buttonText?.isOpened"
    />
  </div>
</template>

<style lang="scss" scoped>
.collapsable_wrapper {
  &.useButton {
    &:before {
      opacity: 0;
      pointer-events: none;
      content: '';
      @apply w-full absolute bottom-0 left-0 block z-[1] transition-opacity duration-200;
      height: 90px;
      background: linear-gradient(360deg, theme('colors.white') 18.13%, theme('colors.white/0') 113.83%);
    }
    &.closed {
      &:before {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}
</style>
