<script lang="ts" setup>
import { ChevronDownIcon } from '@cfgtech/icons'

const props = defineProps<{
  opened: boolean
  openedText?: string
  closedText?: string
}>()

const emit = defineEmits<{
  'update:opened': [boolean]
}>()

const { t } = useI18n()

const buttonLabel = computed(() => {
  if (props.opened) {
    return props.openedText ?? t('common.less')
  }

  return props.closedText ?? t('common.more')
})

function toggle() {
  emit('update:opened', !props.opened)
}
</script>

<template>
  <button
    :aria-label="buttonLabel"
    class="flex gap-x-3 whitespace-nowrap font-normal text-brand transition-opacity duration-200 hover:opacity-90"
    type="button"
    @click="toggle"
  >
    <span class="block underline first-letter:uppercase"> {{ buttonLabel }}</span>

    <ChevronDownIcon :class="{ 'rotate-180': opened }" />
  </button>
</template>
